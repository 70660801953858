import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const AboutUs = () => {
  return (
    <Container fluid className="about-us">
      <Row className="hero-section text-center mb-5">
        <Col md={12}>
          <h1 className="display-4">About Crystal Globe Immigration</h1>
          <p className="lead">Your Gateway to Global Opportunities</p>
        </Col>
      </Row>
      <Row className="mission-section text-left mb-5">
        <Col md={12}>
          <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 1, // Animation duration
              },
            }}
            viewport={{ once: true }}
            className="about-us-mission"
          >
            <div className="about-us-mission-sub">
              <h2 className="display-5">Our Mission</h2>
              <p>
                At Crystal Globe Immigration, our mission is to deliver
                exceptional immigration and document clearing services, ensuring
                a seamless experience for individuals and businesses navigating
                the complexities of international relocation. Based in the heart
                of Dubai, we offer a comprehensive range of services designed to
                facilitate your move and streamline your documentation process,
                whether you're relocating to a new country or managing important
                paperwork.
              </p>
              <h3>Global Immigration Services:</h3>
              <p>
                Our team of experts is dedicated to providing personalized
                solutions for global immigration needs. We specialize in visa
                applications, residency permits, and all related services,
                catering to clients from diverse backgrounds and destinations
                around the world. Our in-depth knowledge of international
                regulations and local requirements ensures that you receive
                accurate, timely, and efficient assistance.
              </p>
              <h3>Document Clearing from Dubai:</h3>
              <p>
                As a trusted name in document clearing, we assist clients with
                the verification, legalization, and translation of important
                documents. Our Dubai-based office offers a central hub for
                processing documents required for various legal and
                administrative purposes, facilitating a smooth and efficient
                experience for our clients.
              </p>
              <h3>Why Choose Us:</h3>
              <ul className="list-unstyled">
                <li>
                  <strong>Expertise:</strong> Our experienced team brings a
                  wealth of knowledge in immigration law and document
                  management.
                </li>
                <li>
                  <strong>Personalized Service:</strong> We tailor our services
                  to meet your specific needs, ensuring a customized approach
                  for each client.
                </li>
                <li>
                  <strong>Efficiency:</strong> Our streamlined processes and
                  dedication to timely service minimize delays and stress.
                </li>
                <li>
                  <strong>Global Reach:</strong> With our comprehensive
                  understanding of international regulations, we provide
                  effective solutions regardless of your destination.
                </li>
              </ul>
            </div>
          </motion.div>
        </Col>
      </Row>
      <Row className="contact-section text-center">
        <Col md={12}>
          <h2 className="display-5">Contact Us</h2>
          <Card className="text-center mx-auto" style={{ maxWidth: "600px" }}>
            <Card.Body>
              <Card.Title>Crystal Globe Immigration</Card.Title>
              <Card.Text>
                Blue Tower
                <br />
                Sheikh Zayed Road
                <br />
                Room 307, 3rd Floor
                <br />
                Dubai, United Arab Emirates
                <br />
                {/* Phone: +971 (565) 857-186<br /> */}
                Email:{" "}
                <a href="mailto:hr@crystalglobeimmigration.com">
                  hr@crystalglobeimmigration.com
                </a>
              </Card.Text>
              <Link
                to="/contact"
                className="submit-button"
                style={{ textDecoration: "none" }}
              >
                Know More
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
