import React from "react";
import emailjs from "emailjs-com";
import contactimg from "./assests/contact.png";
import "./Contact.css";
import { MdAddLocationAlt } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";
import { BsFillTelephonePlusFill } from "react-icons/bs";
import { motion } from "framer-motion";
const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log("Success:", result.text);
          alert("Message sent successfully!");
          e.target.reset();
        },
        (error) => {
          console.log("Error:", error.text);
          alert("Failed to send message.");
        }
      );
  };

  return (
    <div className="container p-3">
      <div className=" g-0  d-flex justify-content-center">
        {/* <div className="col-md-4">
          <img
            src={contactimg}
            alt="contact-us"
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            className="img-fluid"
          />
        </div> */}

        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-6">
                <motion.div
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    x: -50,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                    },
                  }}
                  viewport={{ once: true }}
                  className="h-100 bg-light shadow2 rounded d-flex align-items-center p-5"
                >
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                    style={{ width: "55px", height: "55px" }}
                  >
                    <MdAddLocationAlt color="#8cc63f" />
                  </div>
                  <div className="ms-4">
                    <p className="mb-2">Address</p>
                    <h5 className="mb-0">Blue Tower,Sheikh Zayed Road,Dubai</h5>
                  </div>
                </motion.div>
              </div>
              {/* <div className="col-lg-4">
              <div className="h-100 bg-light shadow2 rounded d-flex align-items-center p-4">
                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{width: '55px', height: '55px'}}>
                
                <MdAttachEmail color="#8cc63f"/>
                </div>
                <div className="ms-4">
                <p className="mb-2">Mail Us Now</p>
                <h6 className="mb-0">info@crystalglobeimmigration.com</h6>
                  
                </div>
              </div>
            </div> */}
              <div className="col-lg-6">
                <motion.div
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    x: 50,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                    },
                  }}
                  viewport={{ once: true }}
                  className="h-100 bg-light shadow2 rounded d-flex align-items-center p-5"
                >
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                    style={{ width: "55px", height: "55px" }}
                  >
                    <BsFillTelephonePlusFill color="#8cc63f" />
                  </div>
                  <div className="ms-4">
                    <p className="mb-2">Call Us Now</p>
                    <h5 className="mb-0">+971 43 407 993</h5>
                  </div>
                </motion.div>
              </div>
              <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  x: -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                  },
                }}
                viewport={{ once: true }}
                className="col-lg-6 wow fadeIn"
                data-wow-delay="0.1s"
              >
                <div className="bg-light shadow1 rounded p-5">
                  <p className="d-inline-block border rounded-pill py-1 px-4">
                    Contact Us
                  </p>
                  <h1 className="mb-4">Have Any Query? Please Contact Us!</h1>

                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            name="from_name"
                            className="form-control"
                            id="name"
                            placeholder="Your Name"
                          />
                          <label htmlFor="name">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="email"
                            name="from_email"
                            className="form-control"
                            id="email"
                            placeholder="Your Email"
                          />
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="tel"
                            id="number"
                            name="from_number"
                            placeholder="Mobile"
                            className="form-control"
                            required
                          />
                          <label htmlFor="number">Mobile Number</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            name="message"
                            placeholder="Leave a message here"
                            id="message"
                            style={{ height: "100px" }}
                            defaultValue={""}
                          />
                          <label htmlFor="message">Message</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn sent-button w-100 py-3"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </motion.div>
              <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  x: -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                  },
                }}
                viewport={{ once: true }}
                className="col-lg-6 shadow1 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <div className="h-100" style={{ minHeight: "400px" }}>
                  <iframe
                    className="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28875.72939556311!2d55.26013963059986!3d25.221218977695454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f42f2869516b3%3A0xad7ca6161b149e91!2sHHHR%20Tower%20-%20Trade%20Centre%20-%20Trade%20Centre%201%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1723712485957!5m2!1sen!2sin"
                    frameBorder={0}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-4">
          <div className="contact-form-2 p-3 w-100">
            <h2 style={{ color: "#fff" }}>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="from_name"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="email"
                  id="email"
                  name="from_email"
                  placeholder="Email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="tel"
                  id="number"
                  name="from_number"
                  placeholder="Mobile"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="inquiry" style={{ color: "#fff" }}>
                  Message
                </label>
                <textarea
                  id="inquiry"
                  name="message"
                  style={{ minHeight: "5rem" }}
                  className="form-control"
                  required
                ></textarea>
              </div>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className="submit-button"
                  style={{ alignSelf: "end" }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
